import { Component, Prop, Mixins } from 'vue-property-decorator';
import template from './ResetPassword.Template.vue';
import ResetPasswordValidator from './ResetPassword.Validator';
import { UtilisateurApiHelper } from '../../../services/UtilisateurApiHelper';
import { Getter, Action } from 'vuex-class';
import { AuthStoreMethods } from '../../../store/modules/auth/AuthStore';
import { Utilisateur } from '@/models';

const env = process.env.NODE_ENV;
const isDev = env === 'dev' || env === 'development';

@Component({
    ...template,
    name: 'ResetPassword',
})
export default class ResetPassword extends Mixins(ResetPasswordValidator) {
    public $refs!: {
        form: HTMLFormElement,
    };

    @Prop({ default: '' }) public readonly code!: string;

    // La promesse pour récupérer le profil de l'utilisateur.
    @Getter(AuthStoreMethods.USER_PROFILE)
    public getUserProfile: Promise<any>;

    // La promesse pour récupérer le profil de l'utilisateur.
    @Action(AuthStoreMethods.LOGOUT)
    public logout: () => Promise<void>;
    
    public modeAffichagePassword: boolean = true;
    public modeAffichagePasswordConfirm: boolean = true;

    private email: string = '';
    private newPassword: string = '';
    private confirmPassword: string = '';

    private loading: boolean = false;

    public created() {
        this.getUserProfile.then((profile: any) => {
            if (!!profile) {
                this.logout();
            }
        });
    }

    /**
     * Régles de confirmation du mot de passe.
     */
    public get passwordConfirmRulesCustom(): any {
        const reglePasswordsIdentiques: ((v: any) => any)[] = [
            (v: string | any) => v === this.newPassword || 'La confirmation du nouveau mot de passe ne correspond pas à la première saisie.'
        ];
        return this.passwordConfirmRules.concat(reglePasswordsIdentiques);
    }

    public submit() {
        if (this.$refs.form.validate()) {
            this.loading = true;
            UtilisateurApiHelper.resetPassword({ email: this.email, password: this.newPassword, code: this.code })
                .then((result: Utilisateur) => {
                    if (result) {
                        (this.$router as any)
                            .push({ name: 'login' })
                            .then(() => {
                                location.reload();
                            })
                            .catch(() => { /*Laisse vide express */ });
                    }
                })
                .finally(() => this.loading = false);
        }
    }

    public back(): void {
        this.$refs.form.reset();
        this.$router.go(-1);
    }
}
